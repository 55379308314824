import React from "react";

const Blogs = () => {
    
    return (

        <div style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'right',
            height: '100vh'
        }} className="pageBody">
            <h1>Blogs coming soon</h1>
        </div>

    )

}
export default Blogs;