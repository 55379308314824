import React from "react";

const About = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'right', 
            alignItems: 'right',
            height: '100vh'
        }} className="pageBody">
            <h1>About us.</h1>
        </div>
    )
}
export default About;